$pozi_white: #FEFEFE;
$pozi_light_gray: #E8EEF4;
$pozi_light_gray_svg: invert(91%) sepia(35%) saturate(84%) hue-rotate(177deg) brightness(98%) contrast(96%);
$pozi_mid_gray: #B4C8DC;
$pozi_mid_gray_svg: invert(83%) sepia(32%) saturate(204%) hue-rotate(175deg) brightness(92%) contrast(86%);
$pozi_dark_gray: #8CA0B4;
$pozi_black: #14181C;
$pozi_black_light: #2C343C;
$pozi_blue: #14B4F0;
$pozi_light_blue: #28C8F0;
$pozi_green: #8CC814;
$pozi_light_green: #a0dc00;
$pozi_orange: #F05014;
$pozi_orange_svg: invert(32%) sepia(55%) saturate(3135%) hue-rotate(358deg) brightness(101%) contrast(88%);
$pozi_yellow: #F0C800;
$pozi_yellow_svg: invert(82%) sepia(70%) saturate(4667%) hue-rotate(1deg) brightness(93%) contrast(105%);
$pozi_light_yellow: #F0DC14;

$pozi_blue_linear: linear-gradient(91.2deg, #28C8F0 0%, #14B4F0 100%);
$pozi_orange_linear: linear-gradient(91.2deg, #FA7800 0%, #F05014 100%);

$sidebar_width: 240px;
$main_padding: 40px;
$scrollbar_width: 17px;
$breadcrumb_height: 120px;
