@import "variables";

.tippy-box[data-theme~='laundry-dropdown'] {
  background-color: $pozi_white;
  border: 0;
  border-radius: 12px;
  color: $pozi_black;
  filter: drop-shadow(0px 4px 12px rgba(44, 52, 60, 0.24));
  font-family: Inter, sans-serif;
  font-size: 16px;

  >.tippy-arrow {
    display: none;
  }
}

.tippy-box[data-theme~='laundry-datepicker'] {
  background-color: $pozi_white;
  border: 0;
  border-radius: 12px;
  color: $pozi_black;
  filter: drop-shadow(0px 4px 12px rgba(44, 52, 60, 0.24));
  font-family: Inter, sans-serif;
  font-size: 16px;
  padding: 10px;

  >.tippy-arrow {
    display: none;
  }
}

.tippy-box[data-theme~='laundry-context-dropdown'] {
  background-color: $pozi_white;
  border: 0;
  border-radius: 12px;
  color: $pozi_black;
  filter: drop-shadow(0px 4px 12px rgba(44, 52, 60, 0.24));
  font-family: Inter, sans-serif;
  font-size: 16px;

  >.tippy-arrow {
    display: none;
  }

  .tippy-content {
    padding: 0;
  }

  &[data-placement^='top'] > .tippy-arrow {
    &:before {
      border-top-color: #fff
    }

    &:after {
      border-top-color: $pozi_light_gray;
      border-width: 7px 7px 0;
      top: 17px;
      left: 1px
    }
  }

  &[data-placement^='bottom'] > .tippy-arrow {
    &:before {
      border-bottom-color: #fff
    }

    &:after {
      border-bottom-color: $pozi_light_gray;
      border-width: 0 7px 7px;
      bottom: 17px;
      left: 1px
    }
  }

  &[data-placement^='left'] > .tippy-arrow {
    &:before {
      border-left-color: #fff
    }

    &:after {
      border-left-color: $pozi_light_gray;
      border-width: 7px 0 7px 7px;
      top: 1px;
      left: 17px
    }
  }

  &[data-placement^='right'] > .tippy-arrow {
    &:before {
      border-right-color: #fff
    }

    &:after {
      border-right-color: $pozi_light_gray;
      border-width: 7px 7px 7px 0;
      top: 1px;
      right: 17px
    }
  }
}

