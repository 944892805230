@import 'src/styles/inter';
@import 'material-icons/iconfont/material-icons.scss';
@import 'src/styles/variables';
@import 'src/styles/tippy-laundry';
@import 'material-symbols';


html, body {
  background-color: $pozi_light_gray;
  color: $pozi_black;
  height: 100%;
  font-family: Inter, sans-serif;
  font-size: 16px;
  margin: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: $pozi_black;
  text-decoration: none;
}

h4 {
  color: $pozi_black;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  height: 40px;
  margin: 21px 0 11px;
}

h5 {
  color: $pozi_black;
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  height: 40px;
  line-height: 19px;
  letter-spacing: 0;
  margin: 0;
  text-align: left;
}

h6 {
  font-size: 16px;
}

.blue {
  color: $pozi_blue !important;
}

.green {
  color: $pozi_green !important;
}

.light-green {
  color: $pozi_light_green !important;
}

.orange {
  color: $pozi_orange !important;
}

.yellow {
  color: $pozi_yellow !important;
}

.mid-gray {
  color: $pozi_mid_gray !important;
}

.dark-gray {
  color: $pozi_dark_gray !important;
}

.black-light {
  color: $pozi_black_light !important;
}

.align-right {
  text-align: right;
}

.bold {
  font-weight: 600;
}

.strikethrough {
  text-decoration: line-through;
}

@for $i from 1 through 3 {
  .r-#{$i*90} {
    transform: rotate(#{$i*90}deg);
  }
}

.icon, button, .dropdown, .button {
  align-items: center;
  background: $pozi_light_gray;
  border-radius: 12px;
  color: $pozi_black;
  display: flex;
  flex-direction: row;
  padding-left: 12px;

  &.blue {
    background: $pozi_blue_linear;
    border-color: transparent;
    color: $pozi_white !important;
  }

  &.orange {
    background: $pozi_orange_linear;
    border-color: transparent;
    color: $pozi_white !important;
  }

  i {
    margin-right: 8px;
  }
}

button, .dropdown, .button {
  border: 0;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 8px 12px 8px 8px;

  &.disabled, &[disabled] {
    cursor: not-allowed;
    opacity: .5;
  }
}

.icon {
  padding: 8px;
}

div.info {
  align-items: center;
  background-color: transparent;
  border: 1px solid $pozi_mid_gray;
  border-radius: 12px;
  color: $pozi_black;
  display: flex;
  flex-direction: row;
  line-height: 20px;
  padding: 9px 12px 9px 8px;

  i[class^='material-icons'] {
    color: $pozi_dark_gray;
    line-height: 20px;
    margin-right: 10px;
  }

  &.orange {
    background-color: rgba($pozi_orange, .2);
    border-color: transparent;

    i[class^='material-icons'] {
      color: $pozi_orange;
    }
  }
}

div.input {
  align-items: center;
  border: 1px solid $pozi_mid_gray;
  border-radius: 12px;
  display: flex;
  padding: 9px 12px 9px 8px;

  input {
    background-color: transparent;
    border: 0;
    color: $pozi_black;
    font-size: 16px;
    line-height: 16px;
    outline: 0;
    width: 220px;
  }

  > * {
    color: $pozi_dark_gray;
    line-height: 20px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  input + * {
    margin-left: auto;
    margin-right: 8px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.progress {
  background-color: $pozi_light_gray;
  border-radius: 5px;
  height: 10px;
  width: 80px;

  &-bar {
    height: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: $pozi_dark_gray;
    background-size: 1rem 1rem;
    max-width: 100%;
    width: 0;

    &.striped {
      background-image: linear-gradient(
          135deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    }

    &.blue {
      background-color: $pozi_blue;
    }

    &.green {
      background-color: $pozi_green;
    }

    &.orange {
      background-color: $pozi_orange;
    }

    &.yellow {
      background-color: $pozi_yellow;
    }
  }
}

.table {
  display: grid;

  .header {
    align-items: center;
    display: flex;
    font-weight: 600;
    padding: 8px 20px 8px 0;
    user-select: none;

    &.sortable {
      cursor: pointer;
    }
  }

  .cell {
    align-items: center;
    align-self: stretch;
    border-top: 1px solid $pozi_mid_gray;
    display: flex;
    padding: 8px 20px 8px 0;

    .inner-double {
      display: flex;
    }

    &.double, .inner-double {
      align-items: start;
      flex-direction: column;
      justify-content: center;
    }

    &.numeric {
      justify-content: flex-end;
    }

    &.center {
      justify-content: center;
    }

    .truck {
      background-color: #E8EEF4;
      color: #14181C;
      border-radius: 6px;
      line-height: 30px;
      text-align: center;
      border-left: 10px solid #14B4F0;
      padding-left: 8px;
      padding-right: 8px;
    }

    .container {
      color: $pozi-white;
      background-color: $pozi-black-light;
      font-weight: 600;
      font-size: 16px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.flex-end {
  justify-content: flex-end;
}

.carts {
  padding-bottom: 40px;
  width: calc(100vw - #{$sidebar_width + 2*$main_padding + $scrollbar_width});

  .cart {
    border-bottom: 1px solid $pozi_mid_gray;
    position: relative;
    height: 40px;
    overflow: hidden;
    transition: height 0.5s;

    &.open {
      height: 130px;
    }

    .cart-header {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;

      > *:not(h6) {
        margin-left: 10px;
        margin-right: 14px;
      }

      h6 {
        margin: 0 auto 0 0;
      }

      strong {
        font-weight: 600;
      }
    }

    &:before {
      display: block;
      content: "";
      top: 40px;
      left: 0;
      height: calc(100% - 40px);
      position: absolute;
      background: linear-gradient(90deg, #FEFEFE 0%, rgba(254, 254, 254, 0) 20px, rgba(254, 254, 254, 0) 100%);
      width: 100%;
      z-index: 1;
      pointer-events: none;
    }

    .cards {
      padding-bottom: 10px;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      overflow-x: auto;
      //width: calc(100vw - 320px);
      width: 100%;
      position: relative;


      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none; /* IE and Edge */
      //noinspection CssUnknownProperty
      scrollbar-width: none; /* Firefox */

      .card {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 180px;
        height: 80px;
        background: $pozi_light_gray;
        border-radius: 12px;
        flex-grow: 0;
        flex-shrink: 0;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        .card-title {
          padding: 10px;
          height: 40px;
          display: flex;
          align-items: center;

          i {
            margin-left: auto;
            cursor: pointer;
          }
        }

        .card-body {
          display: flex;
          padding-left: 10px;
          padding-right: 10px;
          font-weight: 600;

          .balance {
            margin-right: 0;
            margin-left: auto;
          }
        }

        .progress {
          margin-bottom: 0;
          margin-top: auto;
          height: 10px;
          width: 100%;
          background-color: $pozi-green;
          display: flex;

          > * {
            display: inline-flex;
            height: 100%;
          }

          .shipped {
            background-color: $pozi_green;
          }

          .packing {
            background-image: linear-gradient(135deg, $pozi_light_green 25%, $pozi_green 25%, $pozi_green 50%, $pozi_light_green 50%, $pozi_light_green 75%, $pozi_green 75%, $pozi_green 100%);
            background-size: 28.28px 28.28px;
          }

          .ordering {
            background-image: linear-gradient(135deg, $pozi_light_blue 25%, $pozi_blue 25%, $pozi_blue 50%, $pozi_light_blue 50%, $pozi_light_blue 75%, $pozi_blue 75%, $pozi_blue 100%);
            background-size: 28.28px 28.28px;
          }

          .over {
            background-image: linear-gradient(135deg, $pozi_light_yellow 25%, $pozi_yellow 25%, $pozi_yellow 50%, $pozi_light_yellow 50%, $pozi_light_yellow 75%, $pozi_yellow 75%, $pozi_yellow 100%);
            background-size: 28.28px 28.28px;
          }

          .missing {
            background-color: $pozi_orange;
          }
        }
      }
    }
  }
}

.context-menu .context-menu-action {
  align-items: center;
  border-radius: 0;
  color: $pozi_black;
  cursor: pointer;
  display: flex;
  padding: 9px;
  user-select: none;

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &:not(.disabled):hover {
    background-color: $pozi_light_gray;
  }

  &[type='submit'] {
    background-color: transparent;

    i {
      margin-right: 0;
    }
  }

  &.disabled {
    cursor: not-allowed;

    > * {
      opacity: .3;
    }
  }

  > span {
    margin-left: 10px;
  }

  > i {
    font-size: 24px;
    height: 24px;
    width: 24px;
  }

  &.right-icon {
    > span {
      margin-left: 0;
      margin-right: 10px;
    }

    > i {
      margin-left: auto;
    }
  }

  &:not(:first-child) {
    border-top: 1px solid $pozi_light_gray;
  }
}

.tabs {
  align-items: center;
  box-shadow: 0px -1px inset $pozi_mid_gray;
  display: flex;
  font-weight: 600;
  height: 60px;

  > * {
    border-bottom: 2px solid transparent;
    padding-bottom: 18px;
    padding-top: 20px;

    &.active {
      border-bottom: 2px solid $pozi_blue;
    }

    &:not(:last-child) {
      margin-right: 40px;
    }

    .orange {
      color: $pozi_orange;
      font-weight: 400;
    }

    &.disabled {
      pointer-events: none;
      opacity: .5;
    }
  }
}

i[class^="material-icons"] {
  user-select: none;
}

dl {
  margin: 0;

  dt {
    align-items: center;
    display: flex;
    font-weight: 600;
    margin-top: 20px;

    .logo {
      align-items: center;
      display: flex;
      height: 40px;
      /*justify-content: center;*/
      width: 40px;
    }
  }
}
